import React from 'react'
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'

import LocationPin from './LocationPin'

const apiKey = `${process.env.GOOGLE_MAPS_STATIC_API_KEY}`
const location = {
  address: '2202 NW Roosevelt St, Portland, OR 97210',
  center: [45.538274, -122.697119],
  lat: 45.538274,
  lng: -122.697119
}
const zoomLevel = 14

const H2 = styled.h2`
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
`

const GoogleMap = styled.div`
  width: 100%;
  height: 50vh;
`

const Map = () => (
  <GoogleMap>
    <GoogleMapReact
      bootstrapURLKeys={{key: apiKey}}
      defaultCenter={location.center}
      defaultZoom={zoomLevel}
      text={location.address}
    >
      <LocationPin
        lat={location.lat}
        lng={location.lng}
        text={location.address}
      />
    </GoogleMapReact>
  </GoogleMap>
)
export default Map
