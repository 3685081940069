import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components';
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import PortableText from '@sanity/block-content-to-react'

const ModalBacking = styled.div`
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index:30000;
  body {
    overflow:hidden;
  }
`
const ModalWrapper = styled(motion.div)`
  position:fixed;
  padding-top: calc(2vh + 70px);
  padding-bottom:5vh;
  z-index:20000;
  top:0;
  right:0;
  bottom:0;
  left:0;
  @media only screen and (max-width: 800px) {
    overflow-y: auto;
    &::-webkit-scrollbar {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color:white;
      color: white;
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: var(--color-accent-yellow);
    }
  }
  .closebtn {
    position: absolute;
    top: 4vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 50000;
    text-align: center;
    width:90%;
    button {
      margin:0;
      background-color: var(--color-accent-yellow);
    }
    @media only screen and (min-width:800px) {
      top:3vh;
    }
  }
  .closebtn button:hover, .closebtn button:focus {
    transform: scale(1.1);
    background-color: var(--color-accent-blue);
  }
`
const ModalContent = styled(motion.div)`
  position:relative;
  z-index:40000;

  display:grid;
  grid-template-columns: 80%;
  justify-content:center;
  align-content:top;

  p, li {
    text-align:left;
    margin-top:0;
  }
  li {
    font-weight:bold;
    padding:0.25rem 0;
    line-height:1.5rem;
  }
  h3 {
    letter-spacing:1.5px;
    padding-bottom:0.75rem;
  }
  h4 {
    font-weight:300;
    margin-bottom:0.5rem;
    text-decoration:underline;
  }
  .modal-body h4, .modal-programs h4 {
    margin-top:1rem;
  }

  .modal-image {
    .gatsby-image-wrapper {
      margin: 2rem auto 2rem;
      max-height: 400px;
      border-radius: 12px;
    }
  }
  .modal-programs {

    a {
      display: inline-block;
      text-align: left;
      margin: 0.5rem 0.25rem;
      padding: 0.25rem 1rem;
      border: 1px solid var(--color-black);
      border-radius: 12px;
      color: var(--color-black);
      background-color: var(--color-accent-yellow);
      transition: var(--transition);
    }
    a:hover, a:focus {
      transform: scale(1.05);
      border:1px solid transparent;
      color:white;
      background-color: var(--color-accent-blue);
      transition: var(--transition);
    }
  }

  @media only screen and (min-width: 800px) {
    margin: auto;
    width:90%;
    grid-template-columns: 45% 1fr;
    grid-template-rows: auto 1fr;
    max-height: 100%;
    align-items:start;
    h3 {
      padding-top:1rem;
    }

    .modal-image {
      grid-column:1;
      grid-row:1;
    }
    .modal-programs {
      grid-column:1;
      grid-row:2;
    }

    .modal-body {
      padding: 0 1.5rem;
      grid-column: 2;
      grid-row:1/3;
      background-color: var(--color-white-secondary);
      border: 1px solid black;
      border-radius: 8px;
      padding-bottom:0.5rem;

      max-height: 100%;

      overflow-y: auto;
      overflow-x:hidden;
      &::-webkit-scrollbar {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 12px;
        background-color:white;
        color: white;
        width: 12px;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        border-radius: 12px;
        background-color: var(--color-accent-yellow);
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    width:75%;
  }
`

const CoachPopup = ({idArray, coach, handleCoachChange}) => {
  const activeCoach = coach
  const container = {
    hidden: { opacity: 0},
    show: {
      opacity: 1,
      transition: {
        duration: 0.1,
        staggerChildren: 0.025,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        staggerChildren: 0,
        duration: .1
      }
    }
  };
  const item = {
    hidden: { opacity: 0},
    show: { opacity: 1, transition: {duration: 0.15}},
  };

  return (
    <AnimatePresence>
      {activeCoach && (
        <>
          <ModalWrapper
            variants={container}
            initial='hidden'
            animate='show'
            exit='exit'
          >
            <motion.div className="closebtn">
              <motion.button onClick={() => handleCoachChange([undefined, undefined])} whileTap={{scale: 0.9, transition: {duration: 0.1}}}>CLOSE</motion.button>
            </motion.div>
            <ModalContent>
              <motion.div className='modal-image'key={`${activeCoach._id}-1`} variants={item}>
                <Img fluid={activeCoach.secondImage.asset.fluid} style={{margin: '1rem', maxHeight: '600px'}} />
              </motion.div>
              <motion.div className='modal-body' key={`${activeCoach._id}-2`} variants={item}>
                <h3>{activeCoach.name}</h3>
                <h4>Bio</h4>
                <PortableText blocks={activeCoach._rawBio} />
                <h4>Education and Experience</h4>
                {activeCoach.certifications.map((cert, index) => (
                  <li key={`cert-${index}`}>
                    {cert}
                  </li>
                ))}
              </motion.div>
              <motion.div className="modal-programs" key={`${activeCoach._id}-3`} variants={item}>
                  {activeCoach.certifications && (
                    <>
                      <h4>Programs</h4>
                      {activeCoach.program.map((program, index) => (
                        <Link onClick={() => handleCoachChange([undefined, undefined])} to={`/${program.slug.current}`} key={`program-${index}`}>
                          {program.title}
                        </Link>
                      ))}
                    </>
                  )}
                </motion.div>
            </ModalContent>
            <ModalBacking onClick={() => handleCoachChange([undefined, undefined])} />
          </ModalWrapper>
        </>
      )}
    </AnimatePresence>
  )
}

export default CoachPopup
