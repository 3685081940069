import React from 'react'
import styled from 'styled-components'
import {FaMapPin} from 'react-icons/fa'

const Pin = styled.div`
  color: black;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transform: translate(-50%, -50%);
`

const LocationPin = () => {
  return (
    <Pin>
      <FaMapPin style={{height: '1.75rem', width: '1.75rem'}} />
    </Pin>
  )
}

export default LocationPin
