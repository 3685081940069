import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { graphql } from 'gatsby';
import Img from 'gatsby-image'
import { wrap } from "@popmotion/popcorn"
import BackgroundImage from 'gatsby-background-image'
import SEO from '../components/utility/SEO'


import CoachPopup from '../components/about/CoachPopup'
import Map from '../components/about/Map';

const PageWrapper = styled.div`
  display: grid;
  margin: 0;
  h1 {
    display:none;
    background-color: var(--color-white-secondary);
    margin: 0;
    padding: 3rem 0;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1px;
  }
  h2 {
    margin-bottom: 1rem;
    font-style: italic;
  }
  & > * {
    padding: 5vh 5vw;
  }
`;

const CoachesWrapper = styled(motion.div)`
  display:grid;
  h2 {
    text-align:center;
  }
  @media only screen and (min-width:1000px) {
    margin: auto;
  }
  max-width:1000px;
`;
const CoachesGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: 47.5% 47.5%;
  grid-row-gap:1rem;
  grid-column-gap:5%;
  justify-self:stretch;
  @media only screen and (min-width: 800px) {
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 5%;
  }
  @media only screen and (min-width: 1000px) {
    grid-template-columns: repeat(4, minmax(200px, 250px));
    grid-column-gap: 1%;
    justify-self:center;
  }
`
const CoachContainer = styled(motion.div)`
  transition: var(--transition);
  &:hover {
    transform: scale(1.05);
    transition: var(--transition);
  }
  & > h4 {
    text-align:center;
    margin: 0.5rem 0;
  }
  & > div > div.gatsby-image-wrapper {
    cursor:pointer;
    border-radius: 4px;
  }
`
const FacilityWrapper = styled(BackgroundImage)`
  display:grid;
  position:relative;
  color:var(--color-white-secondary);

  opacity: 1 !important;
  background-size: cover;
  background-color: rgba(0,0,0,0.55);
`

const FacilityContainer = styled.div`
  display:grid;
  grid-template-columns: auto;
  max-width:1000px;
  margin:auto;
  @media only screen and (min-width:800px) {
    grid-template-columns: 45% 55%;
    grid-template-rows: auto auto;
    grid-row-gap:3%;
    &>*:nth-child(1) {
      grid-column:1;
      grid-row:1/2;
    }
    &>*:nth-child(2) {
      grid-column: 2/3;
      grid-row: 1/2;
      /* margin: 3.075rem 2.5rem 0 2.5rem; */
    }
    &>*:nth-child(3) {
      grid-column:1/3;
    }
  }
  .caption-container {
    position:relative;
  }
  @media only screen and (min-width:600px) {
    .caption-container {
      margin: 0 1.5rem;
    }
  }
  .image-caption {
    display: block;
    margin-bottom:1rem;
    text-align: center;
    font-style: italic;
    min-height:3rem;
  }
  .arrow {
    display:none;
  }
  @media only screen and (min-width:600px) {
    .image-caption {
      padding: 0 3rem;
    }
    .arrow {
      top:50%;
      position: absolute;
      display: block;
      color: var(--color-black);
      background-color: rgba(255,255,255,0.8);
      padding: 0.75rem;
      border-radius: 100%;
      height: 1rem;
      width: 1rem;
      font-size: 1rem;
      line-height: 1rem;
      text-align: center;
      transition:var(--transition);
      cursor:pointer;
    }
    .arrow:hover {
      background-color:var(--color-accent-blue);
      color:var(--color-white);
      transition:var(--transition);
    }
    .left {
      left:0;
      transform: rotate(180deg) translateY(50%);
    }
    .right {
      right: 0;
      transform: translateY(-50%);
    }
  }

`

const LocationWrapper = styled.div`
position:relative;
padding:0;
margin:0;
width:100%;
h2 {
  position: absolute;
  z-index: 1000;
  top: 1rem;
  left: 1rem;
  width: fit-content;
  background-color: rgba(0,0,0,0.75);
  border-radius: 8px;
  color: white;
  padding: 0.5rem;
  font-style: normal;
 }
`;
const SlideShow = styled.div`
  position:relative;
  display:grid;
  min-height: 300px;
  margin: 0.5rem 1rem;
  overflow-x:hidden;

  @media only screen and (max-width:450px) {
    margin:0;
    min-height:250px;
  }
  @media only screen and (min-width:600px) {
    min-height:350px;
    margin: 1rem 2rem;
  }
  @media only screen and (min-width:800px) {
    min-height:300px;
    margin: 3.075rem 1rem 1rem;
  }
  @media only screen and (min-width:1000px) {
    margin: 3.075rem 1.5rem 1rem;
  }
  @media only screen and (min-width:1200px) {
    margin: 3.075rem 1.5rem 1rem;
  }
`
const ImageWrapper = styled(motion.div)`
  position:relative;
  height:inherit;
  & > div {
    border-radius:8px;
  }
  .gatsby-image-wrapper {
    border-radius:8px;
    height:inherit;
  }
  &::before {
    cursor:pointer;
    content:"";
    position: absolute;
    background-color: transparent;
    z-index: 10;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
  }
`

const About = ({ data }) => {
  const { sanitySettings } = data
  const coaches = data.allSanityCoach.nodes
  const images = data.sanityAboutpage.facilityImages
  const container = {
    hidden: { opacity: 0},
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.075,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        staggerChildren: 0,
        duration: .1
      }
    }
  };
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const coachArray = coaches.map((coach) => coach._id)
  const [[activeCoach, _id], setActiveCoach] = useState([undefined, undefined])


  //Slide Show Configuration
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, images.length, page);
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };
  const slideshowVariants = {
    enter: direction => ({
      x: direction > 0 ? -1000 : 1000,
      opacity: 0
    }),
    center: {
      x: 0,
      opacity: 1
    },
    exit: direction => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    })
  };

  useEffect(() => {
    //Disable <body> scrolling when modal popup is active
    if(activeCoach) {
      document.getElementsByTagName('body')[0].style.overflow="hidden";
    }
    if(activeCoach === undefined) {
      document.getElementsByTagName('body')[0].style.overflow="initial";
    }
    // Auto-Rotate the facility slideshow every 6 seconds
    const interval = setInterval(() => {
      paginate(1);
    }, 7000);
    return () => clearInterval(interval)
  })

  return (
    <>
      <SEO title={'About X-Factor'} description={"Our coaches, facility, and community"} keywords={sanitySettings.keywords} />
      <PageWrapper>
        <h1>About</h1>
        <CoachesWrapper>
          <h2>Meet Our Team</h2>
            <CoachesGrid variants={container} initial="hidden" animate="show">
              {coaches.map((coach, index) => (
                <motion.div whileTap={{scale: .95, transition: {duration: 0.15}}} key={index} variants={item}>
                  <CoachContainer>
                    <div onClick={() => setActiveCoach([coach, coach._id])}>
                      <Img fluid={coach.image.asset.fluid} />
                    </div>
                    <h4>{coach.name}</h4>
                  </CoachContainer>
                </motion.div>
              ))}
              <CoachPopup idArray={coachArray} coach={activeCoach} coachId={_id} handleCoachChange={setActiveCoach} />
            </CoachesGrid>
        </CoachesWrapper>
        <FacilityWrapper fluid={data.sanityAboutpage.facilityBackgroundImage.asset.fluid}>
          <FacilityContainer>
            <div>
              <h2>Our Facility</h2>
              <p>{data.sanityAboutpage.facilityDescription}</p>
            </div>
            <div>
              <SlideShow>
                <AnimatePresence initial={false} custom={direction}>
                  <motion.div
                    key={page}
                    variants={slideshowVariants}
                    style={{
                      position:'absolute',
                      width:'100%',
                      height:'100%'
                    }}
                    custom={direction}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      x: { type: "spring", stiffness: 300, damping: 30 },
                      opacity: { duration: 0.2 }
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(e, { offset, velocity }) => {
                      if (offset.x > 125) {
                        paginate(1);
                      } else if (offset.x < -125) {
                        paginate(-1);
                      }
                    }}
                  >
                    <ImageWrapper>
                      <Img fluid={images[imageIndex].asset.fluid} />
                    </ImageWrapper>
                  </motion.div>
                </AnimatePresence>
              </SlideShow>
              <div className="caption-container">
                <div onClick={() => paginate(-1)} className="arrow left">&#9658;</div>
                <div onClick={() => paginate(1)} className="arrow right">&#9658;</div>
                <motion.p key={`caption-${imageIndex}`} initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} className={'image-caption'}>{images[imageIndex].caption}</motion.p>
              </div>
            </div>
            <div>
              <h3>Our Community</h3>
              <p>{data.sanityAboutpage.communityText}</p>
            </div>
          </FacilityContainer>
        </FacilityWrapper>
        <LocationWrapper>
          <h2>Find us on a map</h2>
          <Map />
        </LocationWrapper>
      </PageWrapper>
    </>
  );
};

export default About;

export const query = graphql`
  query aboutQuery {
    sanitySettings {
      keywords
    }
    sanityAboutpage {
      facilityDescription
      facilityBackgroundImage {
        asset {
          fluid(maxWidth:1920) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
      communityText
      facilityImages {
        alt
        caption
        asset {
          fluid(maxWidth:800) {
            ...GatsbySanityImageFluid
          }
        }
     }
    }
    allSanityCoach(sort: { fields: name, order: ASC }) {
      nodes {
        _id
        name
        image {
          asset {
            fluid(maxWidth: 600, maxHeight: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        secondImage {
          asset {
            fluid(maxHeight:600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        program {
          title
          slug {
            current
          }
        }
        certifications
        _rawBio
      }
    }
  }
`;
